/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.0.0-v202409132239-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import * as models from './models';

/**
 * Returns an item descendant that matches certain properties. Note that only a single descendant can be returned. If multiple descendants match, or none match, null is returned. Column type must be UUID
 */
export interface ColumnRuleDescendantInputV1 {
    /**
     * The index of the column that references the item to search descendants for. The column index is 1-based
     */
    "columnIndex": number;
    /**
     * The type of item to search for. If not specified, the rule will search for all item                         types. If specified, it must be a valid item type. For example, if the column references an Area,                          the itemType could be \"Asset\" to search for all assets under the area. If the column references                         an Asset, the itemType could be \"Condition\" to search for all conditions under the asset
     */
    "itemType"?: string;
    /**
     * The level which should be searched. If not specified, the rule will search all levels.If specified, it must be greater than 0, and only the specified level will be searched.
     */
    "level"?: number;
    /**
     * The operator to use for matching the property value. Available options are EQUALS, EQUALS_IGNORE_CASE, NOT_EQUALS, WITHIN and STRING_CONTAINS
     */
    "propertyMatchOperator": PropertyMatchOperatorEnum;
    /**
     * The property name to search
     */
    "propertyName": string;
    /**
     * The property value to search for
     */
    "propertyValue": string;
}

export enum PropertyMatchOperatorEnum {
    EQUALS = 'EQUALS' as any,
    EQUALSIGNORECASE = 'EQUALS_IGNORE_CASE' as any,
    NOTEQUALS = 'NOT_EQUALS' as any,
    WITHIN = 'WITHIN' as any,
    STRINGCONTAINS = 'STRING_CONTAINS' as any
}
